import { Button, Flex, HStack, Text, VStack } from '@chakra-ui/react';

export default function BuyPrompt({ prompt }) {
  return (
    <Flex layerStyle='prompt-over'>
      <VStack>
        <Button>{prompt}</Button>
      </VStack>
    </Flex>
  );
}
