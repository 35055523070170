import {
  Box,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import BrandToken from '~/pages/landing/brand-token.jsx';
import SignUpFixed from '~/components/sign-up-fixed.jsx';
import { HorizontalBrandRow } from '~/pages/landing/helpers.jsx';

const BREAKPOINTS = {
  base: 'outline',
  sm: {
    orientation: 'column',
    showFooter: false,
    height: 'auto',
  },
  md: {
    orientation: 'column',
    showFooter: false,
    height: 'auto',
  },
  lg: {
    orientation: 'row',
    showFooter: true,
    height: '100%',
  },
  xl: {
    orientation: 'row',
    showFooter: true,
    height: '100%',
  },
};

export function LandingPage03SignUp({ imgBrands, state }) {
  const whichElements = useBreakpointValue(BREAKPOINTS, {
    fallback: 'md',
  });

  return (
    <Flex layerStyle='landing-page-one' h={whichElements.height} position='relative'>
      <Stack
        width='100%'
        spacing={'20px'}
        direction={whichElements.orientation}
        alignItems='center'
      >
        <Box layerStyle='landing-page-intro-text-panel-3' textAlign={['center', 'center', 'left']}>
          <Heading mt={['20px', '50px', 0]} variant='lp'>
            Ready to get started?
          </Heading>
          <Text textStyle='landing-page-intro'>
            Sign up and start earning. {whichElements.orientation}
          </Text>
        </Box>
        <Box
          height={whichElements.height}
          flex={1}
          position='relative'
          data-row='right-panel'
          mb={['50px', '100px', '100px']}
        >
          <Stack height='100%' width='100%' justify='center' alignItems='center'>
            <SignUpFixed />
          </Stack>
        </Box>
      </Stack>
      <HorizontalBrandRow imgBrands={imgBrands} showFooter={whichElements.showFooter} />
    </Flex>
  );
}
