'use client';
import { Box } from '@chakra-ui/react';

import PageContainerInner from '~/components/page-container-inner.jsx';
import BrandsList from '~/pages/brands/brands-list.jsx';

export default function BrandsPage() {
  return (
    <Box layerStyle='page-container' id='brand-page-container' overflowY='scroll'>
      <PageContainerInner>
        <BrandsList />
      </PageContainerInner>
    </Box>
  );
}
