import { Button, HStack, Text } from '@chakra-ui/react';
import account from '~/lib/state/account-state.js';
import { Link } from 'react-router-dom';
import useGlobalForest from '~/hooks/useGlobalForest.jsx';

export default function User(props) {
  const { isLoggedIn } = useGlobalForest(account);

  if (isLoggedIn)
    return (
      <HStack spacing={6}>
        <Link to='/account'>
          <Text textStyle='header-menu-item'>My Account</Text>
        </Link>
        <Button onClick={account.do.signOut}>Log Out</Button>
      </HStack>
    );

  return (
    <HStack spacing={6}>
      <Link to='/account'>
        <Text textStyle='header-text'>My Account</Text>
      </Link>
      {props.small ? null : (
        <>
          <Button variant='outline' onClick={account.do.startSignIn}>
            Sign In
          </Button>

          <Button onClick={account.do.startSignUp}>Sign Up</Button>
        </>
      )}
    </HStack>
  );
}
