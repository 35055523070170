export const CATEGORIES = [
  {
    category_id: 1,
    category: 'Broadband',
  },
  {
    category_id: 2,
    category: 'Card-linked',
  },
  {
    category_id: 3,
    category: 'Cars',
  },
  {
    category_id: 4,
    category: 'Electronics',
  },
  {
    category_id: 5,
    category: 'Entertainment',
  },
  {
    category_id: 6,
    category: 'Fashion',
  },
  {
    category_id: 7,
    category: 'Finance',
  },
  {
    category_id: 8,
    category: 'Food & Drink',
  },
  {
    category_id: 9,
    category: 'Gift Cards',
  },
  {
    category_id: 10,
    category: 'Gifts',
  },
  {
    category_id: 11,
    category: 'Health & Beauty',
  },
  {
    category_id: 12,
    category: 'Home & DIY',
  },
  {
    category_id: 13,
    category: 'Insurance',
  },
  {
    category_id: 14,
    category: 'Mobile Phones',
  },
  {
    category_id: 15,
    category: 'Office & Business',
  },
  {
    category_id: 16,
    category: 'Pets',
  },
  {
    category_id: 17,
    category: 'Sports & Fitness',
  },
  {
    category_id: 18,
    category: 'Toys & Games',
  },
  {
    category_id: 19,
    category: 'Travel',
  },
  {
    category_id: 20,
    category: 'Wedding',
  },
];

export const ART_CATEGORIES = [
  'Animals',
  'Letters',
  'Birds',
  'Numbers',
  'Body Parts',
  'Relatives',
  'Colored Items – blue',
  'Shapes',
  'Colored Items – green',
  'Sounds',
  'Colored items – red',
  'Things with buttons',
  'Colored items -yellow',
  'Things you turn on/off',
  'Days of the week',
  'Toys',
  'Desserts',
  'Vegetables',
  'Farm animals',
  'Wild animals',
  'Foods',
  'Words',
  'Fruits',
  '',
  'Early Elementary',
  'Classroom Objects',
  'Nicknames',
  'Clothing',
  'Pets',
  'Colors',
  'Places to Eat',
  'Colored Items – Orange',
  'Planets',
  'Condiments',
  'Reptiles',
  'Creatures in the Ocean',
  'School Subjects',
  'Drinks',
  'Sports/Games',
  'Electronic Goods',
  'Television',
  'Emotions',
  'Things at the Beach',
  'First Names',
  'Things with Pockets',
  'Jobs',
  'Things with Wheels',
  'Kitchen Utensils',
  'Tools',
  'Meats',
  'Transportation',
  'Months',
  'Types of Breakfast',
  'Musical Instruments',
  'Ways to Exercise',
  'Names for a Dog',
  'Weather',
  'Late Elementary',
  'Bathroom Items',
  'Places',
  'Bedroom Items',
  'Presidents',
  'Buildings',
  'Shops',
  'Cars',
  'States in the US',
  'Comic Book Heroes',
  'Textures',
  'Dairy Products',
  'Things found under the bed',
  'Famous Athletes',
  'Things made of fabric',
  'Fast Food',
  'Things made of glass',
  'Football Teams',
  'Things made of metal',
  'Furniture',
  'Things made of wood',
  'Habitats',
  'Things that are alive',
  'Holidays & Celebrations',
  'Things that float',
  'Hotels',
  'Things that fly',
  'Junk Food',
  'Things that grow',
  'Languages',
  'Things that use batteries',
  'Liquids',
  'Things that use electricity',
  'Mammals',
  'Things you hang on a wall',
  'Materials',
  'Things you plug in',
  'Movies',
  'Verbs',
  'Nouns',
  'Ways to cook',
  'Secondary Grades',
  'Adjectives',
  'Hobbies',
  'Adverbs',
  'Internal organs',
  'Board Games',
  'Languages',
  'Careers',
  'Male names',
  'Cities',
  'Musical genres',
  'Clothing store',
  'Nationalities',
  'College majors',
  'Personalities',
  'Countries',
  'Recipes',
  'Cuisine',
  'Restaurants',
  'Famous musicians',
  'Song titles',
  'Famous people',
  'States',
  'Female names',
  'Technology',
  'Gardening',
  'Gasses',
];

export const BRANDS_PER_LOAD = 20;

export const BRAND_ID_KEY = 'brand_uid';

export const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};
export const MIN_CHARS = 8;

export const ACCOUNT_MODE = {
  neutral: 'neutral',
  signingUp: 'signingUp',
  signingIn: 'signingIn',
};
