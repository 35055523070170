import { Input, Box, InputGroup, InputLeftAddon } from '@chakra-ui/react';

export default function Search(props) {
  return (
    <Box {...props}>
      <InputGroup>
        <InputLeftAddon
          backgroundColor='white'
          borderTopLeftRadius='20px'
          borderBottomLeftRadius='20px'
        >
          <img alt='search icon' src='/icons/text-search.svg' />
        </InputLeftAddon>
        <Input
          borderLeftColor='white'
          backgroundColor='white'
          borderTopRightRadius='20px'
          borderBottomRightRadius='20px'
          name='search'
          placeholder='search for brands'
        />
      </InputGroup>
    </Box>
  );
}
