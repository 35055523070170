import {
  SCHEMA_DATE,
  SCHEMA_NUMBER,
  SCHEMA_POS_NUMBER,
  SCHEMA_STATUS,
  SCHEMA_STRING,
  SCHEMA_UUID,
} from '~/lib/database/data-helpers.js';

export default {
  offer_uid: SCHEMA_UUID,
  brand_uid: SCHEMA_UUID,
  name: SCHEMA_STRING,
  description: SCHEMA_STRING,
  banner_url: {
    type: 'string',
  },
  refund_period_days: SCHEMA_NUMBER,
  max_spend: SCHEMA_POS_NUMBER,
  min_spend: SCHEMA_POS_NUMBER,
  start_date: SCHEMA_DATE,
  end_date: SCHEMA_DATE,
  status: SCHEMA_STATUS,
  done: {
    type: 'boolean',
  },
  insert_order_contract_url: SCHEMA_STRING,
  terms_and_conditions: SCHEMA_STRING,
};
