import { Forest } from '@wonderlandlabs/forest';

/**
 * This is a "general purpose shared state"
 */
const globalState = new Forest({
  name: 'globalState',
  $value: {
    openOfferID: '',
  },
  selectors: {},
  actions: {
    message(state, toastData, textAlternative) {
      const toast = state.getMeta('toast');
      const msg = textAlternative ?? `${toastData.title} ${toastData.description}`;

      if (toast) {
        toast({
          status: 'info',
          duration: 4000,
          isClosable: true,
          ...toastData,
        });
        console.info(msg);
      } else {
        window.alert(msg);
        console.error(msg);
      }
    },
  },
});

export default globalState;
