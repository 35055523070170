import { Box, Flex, HStack, useBreakpointValue } from '@chakra-ui/react';
import LandingSampleBrandCard from '~/pages/landing/landing-sample-brand-card.jsx';
import { HorizontalBrandRow, ImageBox, IntroText } from '~/pages/landing/helpers.jsx';

const BREAKPOINTS = {
  base: 'outline',
  sm: {
    showImage: false,
    showButtons: false,
    showSample: false,
    showFooter: true,
  },
  md: {
    showImage: false,
    showButtons: false,
    showSample: false,
    showFooter: true,
  },
  lg: {
    showImage: false,
    showButtons: false,
    showSample: true,
    showFooter: true,
  },
  xl: {
    showImage: true,
    showButtons: true,
    showSample: true,
    showFooter: true,
  },
};

export function LandingPage01Intro({ brands, imgBrands }) {
  const whichElements = useBreakpointValue(BREAKPOINTS, { fallback: 'md' });

  return (
    <Flex layerStyle='landing-page-one' h='100%' position='relative'>
      <HStack width='100%' spacing={[0, '10px', '15px', '10vw']}>
        <IntroText />
        <Box height='100%' flex={1} position='relative'>
          <Flex
            height='100%'
            width='100%'
            direction='row'
            justify='center'
            alignItems='center'
            zIndex={20}
          >
            <LandingSampleBrandCard brand={brands[0]} which={whichElements} />
            <ImageBox showImage={whichElements.showImage} />
          </Flex>
        </Box>
      </HStack>
      <HorizontalBrandRow showFooter={whichElements.showFooter} imgBrands={imgBrands} />
    </Flex>
  );
}
