import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import {
  HERO_HEAD_BRAND,
  MAJOR_HEAD,
  MINOR_HEAD,
  OFFER_BRAND,
  OFFER_HEAD,
} from '~/theme/constants.js';

/**
 * A NOTE ON FONT FAMILY
 * The default font of text in this site is Roboto.
 * If an element is set to fontFamily: 'display', the font family is set to Poppins.
 */

export const headingTheme = defineStyleConfig({
  defaultProps: {
    fontFamily: 'display',
    textAlign: 'left',
    color: 'blackAlpha.800',
  },

  variants: {
    'brand-head': defineStyle({
      fontSize: '13pt',
      fontWeight: 600,
      numOfLines: 1,
      fontFamily: 'display',
      textTransform: 'uppercase',
      noOfLines: 1,
      my: 2,
    }),
    'landing-page-panel': {
      ...MAJOR_HEAD,
      fontSize: ['24px', '36px', '60px'],
      lineHeight: 1.5,
      mt: ['20px', '40px', 'min(20vh,200px)'],
      mb: ['20px', '40px', 'min(10vh, 100px)'],
    },
    'landing-page-gs': {
      ...MINOR_HEAD,
      fontSize: ['24px', '27.5px', '30px'],
      textAlign: 'center',
      mt: ['5px', '5px', '65px'],
      mb: [0, 0, '11px'],
      p: 0,
    },
    'card-header': {
      fontFamily: 'display',
      fontSize: '20px',
      textAlign: 'left',
      fontWeight: 500,
      pt: '22px',
      pb: '8px',
      px: '34px',
      borderBottom: '2px solid',
      borderColor: 'blackAlpha.50',
      display: 'block',
    },
    'card-header-no-line': {
      fontFamily: 'display',
      fontSize: '20px',
      textAlign: 'left',
      fontWeight: 500,
      pt: '22px',
      pb: '8px',
      px: '34px',
      display: 'block',
    },
    'carousel-head': {
      fontSize: '20px',
      color: 'white',
      textShadow: '1px 1px 2px rgba(0,0,0,1);',
      fontFamily: 'display',
      fontWeight: 600,
    },
    'cash-back': defineStyle({
      ...MINOR_HEAD,
      color: 'cash-back-text',
      fontFamily: 'pridi',
      textShadow: '2px 1px 1px rgba(220,255,255,1);',
      m: 0,
      p: 0,
    }),
    'category-head': defineStyle({
      ...MINOR_HEAD,
      fontFamily: 'display',
    }),
    hero: defineStyle({
      ...MAJOR_HEAD,
      fontFamily: 'display',
      textAlign: 'left',
    }),
    'hero-brand': defineStyle({
      ...HERO_HEAD_BRAND,
      fontFamily: 'display',
    }),
    'homepage-head': defineStyle({
      fontSize: '28px',
      textAlign: 'left',
      fontFamily: 'display',
      fontWeight: 600,
      my: 2,
      pt: ['24px', '26px', '27px'],
      pb: '16px',
    }),
    lp: {
      color: 'white',
      fontFamily: 'display',
      fontSize: ['30px', '45px', '65px'],
      lineHeight: '1.1',
      mb: [0, 0, '24px'],
      mt: ['50px', '50px', 0],
    },
    offer: defineStyle({
      ...OFFER_HEAD,
      fontFamily: 'display',
    }),
    'offer-brand': defineStyle({
      ...OFFER_BRAND,
    }),
    'offer-main': {
      fontFamily: 'display',
      textAlign: 'center',
      fontSize: '28px',
      color: 'persian-green',
    },
    'page-head': defineStyle({
      ...MAJOR_HEAD,
      fontFamily: 'display',
    }),
    'page-head-minor': defineStyle({
      ...MINOR_HEAD,
      fontFamily: 'display',
    }),
    prompt: defineStyle({
      ...MINOR_HEAD,
      fontFamily: 'display',
      fontStyle: 'italic',
    }),
    'rewards-box': {
      fontSize: '14px',
      fontWeight: 500,
      textAlign: 'center',
      color: 'persian-green',
    },
    'text-block': defineStyle({
      ...MAJOR_HEAD,
      color: 'persian-green',
      fontFamily: 'display',
    }),
  },
});
