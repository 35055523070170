import { Box, Center } from '@chakra-ui/react';

export default function PageContainerInner({ children, ...props }) {
  return (
    <Box data-role='page-containter-inner' {...props}>
      <Center data-role='page-container-inner-c'>
        <Box layerStyle='page-container-inner-margin' data-role='page-container-m'>
          {children}
        </Box>
      </Center>
    </Box>
  );
}
