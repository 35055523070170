'use client';
import InfiniteScroll from 'react-infinite-scroll-component';

import useForest from '~/hooks/useForest.jsx';
import {
  Box,
  Heading,
  HStack,
  SimpleGrid,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { Brand } from '~/pages/brands/brand.jsx';
import { useEffect } from 'react';

import { brandListState } from '~/pages/brands/brand-list-state.jsx';
import { Store } from '~/pages/brands/store.jsx';
import Carousel from '~/pages/brands/carousel.jsx';

function keyOfSet(offerSet) {
  return offerSet.map((o) => o.brand_uid).join('-');
}

function BrandsListView({ state, brands, more, isLoading }) {
  const brandColumns = useBreakpointValue(
    {
      base: 1,
      sm: 1,
      md: 2,
      lg: 3,
      xl: 4,
    },
    {
      fallback: 'md',
    }
  );

  const storeColumns = useBreakpointValue(
    {
      base: 1,
      sm: 2,
      md: 3,
      lg: 4,
      xl: 6,
    },
    {
      fallback: 'md',
    }
  );

  useEffect(() => {
    state.do.set_brandColumns(brandColumns);
  }, [state, brandColumns]);

  useEffect(() => {
    state.do.set_storeColumns(storeColumns);
  }, [state, storeColumns]);

  const list = state.$.brandList();
  const multiBrandsOnRow = state.$.multiBrandsOnRow();
  const storeChunk = state.$.storeChunk();

  return (
    <VStack layerStyle='brand-list' alignItems='flex-start'>
      <Carousel brands={brands} />
      <Heading variant='homepage-head'>Cash Back at Stores We Love</Heading>
      <SimpleGrid key={keyOfSet(storeChunk)} columns={storeChunk.length} w='100%' p={0} m={0}>
        {storeChunk.map((brand) => (
          <Store key={brand.brand_uid} brand={brand} />
        ))}
      </SimpleGrid>

      <Heading variant='homepage-head'>Top Deals Picked For You!</Heading>
      <InfiniteScroll
        dataLength={list.length}
        next={state.do.loadBrands}
        hasMore={more}
        loader={
          <Box>
            <Text>Loading...</Text>
          </Box>
        }
        scrollableTarget='brand-page-container'
      >
        {multiBrandsOnRow
          ? list.map((brandChunk) => (
              <SimpleGrid key={keyOfSet(brandChunk)} columns={brandColumns} w='100%' p={0} m={0}>
                {brandChunk.map((brand) => {
                  return (
                    <Brand
                      updateCategory={state.do.updateCategory}
                      key={brand.brand_uid}
                      brand={brand}
                    />
                  );
                })}
              </SimpleGrid>
            ))
          : list.map((brand) => (
              <HStack
                data-role='brand-container'
                width='100%'
                justify='center'
                key={brand.brand_uid}
              >
                <Brand updateCategory={state.do.updateCategory} brand={brand} />
              </HStack>
            ))}
      </InfiniteScroll>
      {!isLoading && !brands.size ? (
        <Box>
          <Text>No Brands Found</Text>
        </Box>
      ) : (
        ''
      )}
    </VStack>
  );
}

export default function BrandsList() {
  const [value, state] = useForest(brandListState, null, {
    onCreate(state) {
      state.do.loadBrands();
    },
  });

  if (!state) return;

  return <BrandsListView {...value} state={state}></BrandsListView>;
}
