import { Box, Center, Heading, Image, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import BrandToken from '~/pages/landing/brand-token.jsx';
import imgUrl from '~/lib/helpers.jsx';

// ---- 01 - intro

export const ImageBox = ({ showImage }) =>
  showImage && (
    <Box
      data-role='lp-art-1'
      width='260px'
      height='260px'
      flexBasis='300px'
      flex={1}
      my='100px'
      mr='20%'
      ml='20px'
      background={imgUrl('/img/lp/shutterstock_2140128365.jpg')}
      backgroundSize='contain'
      backgroundRepeat='no-repeat'
    />
  );

export const HorizontalBrandRow = ({ showFooter, imgBrands }) =>
  showFooter && (
    <SimpleGrid
      columns={imgBrands.length}
      layerStyle='landing-page-brand-row-grid'
      data-role='brand-row'
    >
      {imgBrands.map((brand) => (
        <Center key={brand.brand_uid}>
          <BrandToken brand={brand} />
        </Center>
      ))}
    </SimpleGrid>
  );

export const IntroText = () => (
  <Box layerStyle='landing-page-intro-text'>
    <Heading variant='lp'>Free cash from top brand, every time you shop</Heading>
    <Text textStyle='landing-page-intro'>
      The most simple money-saving life hack that you should know about
    </Text>
  </Box>
);

// ---- 02 - getting started
export function GettingStartedColumn({ src, title, children }) {
  return (
    <Box w={['100%', '100%', '350px']} mb={'20px'}>
      <VStack>
        <Image src={src} height={200} width={216} />
        <Heading variant='landing-page-gs'>{title}</Heading>
        {children}
      </VStack>
    </Box>
  );
}

export const GettingStartedCol01 = () => (
  <GettingStartedColumn src='/img/lp/gs/gs-1.svg' title='Link your cards'>
    <Text textStyle='landing-page-gs-txt'>Connect your credit/debit cards easily and securely</Text>
  </GettingStartedColumn>
);

export const GettingStartedCol02 = () => (
  <GettingStartedColumn src='/img/lp/gs/gs-2.svg' title='Go shopping!'>
    <Text textStyle='landing-page-gs-txt'>
      Make sure you use one of your linked cards whenever you shop
    </Text>
  </GettingStartedColumn>
);

export const GettingStartedCol03 = () => (
  <GettingStartedColumn src='/img/lp/gs/gs-3.svg' title='Get cashback'>
    <Text textStyle='landing-page-gs-txt'>Automatically earn cashback on your purchases</Text>
  </GettingStartedColumn>
);
