import { GRAY_ALPHAS } from '~/theme/constants.js';

export default {
  accent: 'hsl(169,100%,36%)',
  'accent-disabled': '#F2F2F2',
  'accent-disabled-txt': '#828B97',
  'accent-dk': 'hsl(157,90%,15%)',
  'accent-lt': 'hsl(157,100%,80%)',
  accents: {
    50: 'hsl(169,100%,5%)',
    100: 'hsl(169,100%,12%)',
    200: 'hsl(169,100%,18%)',
    300: 'hsl(169,100%,24%)',
    400: 'hsl(169,100%,28%)',
    500: 'hsl(169,100%,36%)',
    600: 'hsl(169,100%,42%)',
    700: 'hsl(169,100%,56%)',
    800: 'hsl(169,100%,70%)',
    900: 'hsl(169,100%,90%)',
  },
  'bangladesh-green': 'hsl(169,77%,14%)',
  'bangladesh-green-dk': 'hsl(169,50%,5%)',
  'bangladesh-green-lt': 'hsl(169,77%,50%)',
  'body-bg': 'rgb(255,255,255)',
  'body-text': {
    light: 'rgba(0,0,0,0.73)',
    dark: 'rgba(255,255,255,0.73)',
  },
  'brand-box-background': 'gray.200',
  'brand-primary': 'hsl(169,99%,36%)',
  'brand-tertiary': 'hsl(169,56%,94%)',
  'cash-back-text': 'hsl(8,100%,53%)',
  'header-bg': '#F8F8F8',
  'hero-header': 'rgba(0,0,0,0.8)',
  lime: {
    50: 'hsl(77,100%,5%)',
    100: 'hsl(77,100%,10%)',
    200: 'hsl(77,100%,18%)',
    300: 'hsl(77,100%,26%)',
    400: 'hsl(77,100%,34%)',
    500: 'hsl(77,100%,42%)',
    600: 'hsl(77,100%,50%)',
    700: 'hsl(77,100%,60%)',
    800: 'hsl(77,100%,70%)',
    900: 'hsl(77,100%,90%)',
  },
  limeAlpha: {
    50: 'hsla(77,100%,42%, 5%)',
    100: 'hsla(77,100%,42%, 10%)',
    200: 'hsla(77,100%,42%, 18%)',
    300: 'hsla(77,100%,42%, 26%)',
    400: 'hsla(77,100%,42%, 26%)',
    500: 'hsla(77,100%,42%, 34%)',
    600: 'hsla(77,100%,42%, 42%)',
    700: 'hsla(77,100%,42%, 50%)',
    800: 'hsla(77,100%,42%, 70%)',
    900: 'hsla(77,100%,42%, 90%)',
  },
  ...GRAY_ALPHAS,
  lp: {
    1: '#D5F2BE',
    2: '#92DBCC',
    3: '#3FAEBD',
  },
  'offer-desc': 'rgba(0,0,0,0.9)',
  'offer-desc-rev': 'rgba(255,255,255,0.9)',
  'persian-green': 'hsl(169,100%,36%)',
  'rewards-head': 'hsl(0,0%,27%)',
  'secondary-green': '#E6F8F4',
  'text-bg-dk': 'rgba(255,255,255,0.8)',
  'text-bg-lt': 'rgba(0,0,0,0.8)',
  'secondary-primary': 'hsl(214,9%,55%)',
};
