import { extendTheme } from '@chakra-ui/react';
import { tabsTheme } from './tabs-theme.js';
import { inputTheme } from './input-theme.js';
import { buttonTheme } from './button-theme.js';
import { mode } from '@chakra-ui/theme-tools';
import {
  BGI,
  BRAND_BANNER_HEIGHT,
  BRAND_DESC_BG,
  BRAND_HEAD_BG,
  BRAND_IMAGE_RADIUS,
  BRAND_PADDING,
  BRAND_PAGE_BANNER_PX,
  BRAND_PAGE_LOGO_HEIGHT,
  BRAND_PAGE_LOGO_PX,
  BRAND_SIZE,
  BRAND_SIZE_HERO,
  BRAND_SIZE_SM,
  BRAND_WIDTH,
  BRAND_WIDTH_SM,
  CAROUSEL,
  DESC_BG_DARK,
  DESC_BG_GRAY,
  DESC_BG_LIGHT,
  FORM_MAX_WIDTH,
  HEAD_BG_DARK,
  HEAD_BG_GRAY,
  HEAD_BG_LIGHT,
  HEAD_HERO_BG_LIGHT,
  HERO_HEAD_BRAND,
  HERO_HEAD_TXT,
  IMAGE_COVER,
  MAJOR_HEAD,
  OFFER_DESC,
  OFFER_DESC_BG,
  OFFER_HEAD_BG,
  PAGE_CONTAINER_INNER_MAX_WIDTH_PX,
  STORE_LOGO_SIZE_PX,
  TEXTBOX_MAX_WIDTH,
} from '~/theme/constants.js';
import { headingTheme } from '~/theme/heading-theme.js';
import { modalTheme } from '~/theme/modal-theme.js';
import { cardTheme } from '~/theme/card-theme.js';
import colors from '~/theme/colors.js';

//@ TODO: (big) break out theme in to component based sub-themes
// -- NOT NOW as the site needs to evolve before the uniqueness of dependencies are clear.

export const theme = extendTheme({
  breakpoints: {
    base: '0px',
    sm: '400px',
    md: '800px',
    lg: '1100px',
    xl: '1400px',
    '2xl': '1600px',
  },
  colors,
  components: {
    Tabs: tabsTheme,
    Card: cardTheme,
    Text: {
      variants: {
        'offer-desc': {
          noOfLines: 1,
        },
        'brand-desc': {
          noOfLines: 1,
        },
        'two-lines': {
          noOfLines: 2,
        },
        'one-line': {
          noOfLines: 1,
        },
      },
    },
    Heading: headingTheme,
    Button: buttonTheme,
    Input: inputTheme,
    Modal: modalTheme,
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.25rem',
    xl: '1.5rem',
    '2xl': '2rem',
    '3xl': '2.5rem',
    '4xl': '3rem',
    '5xl': '3.5rem',
    '6xl': '4rem',
    '7xl': '4.5rem',
    '8xl': '5rem',
    '9xl': '6rem',
  },
  fonts: {
    display: '"Poppins", sans-serif',
    body: 'Roboto, sans-serif',
  },
  layerStyles: {
    'bg-image': {
      ...BGI,
      ...IMAGE_COVER,
      flex: 0,
      pointerEvents: 'all',
      top: -1,
      left: -1,
      right: -1,
      bottom: -1,
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
    },
    brand: {
      mx: [0, 0.5, 1],
      my: [0, 0.5, 1],
      position: 'relative',
      width: [BRAND_WIDTH_SM, BRAND_WIDTH_SM, BRAND_WIDTH_SM, BRAND_WIDTH],
      p: 0,
    },
    'brand-box': {
      width: BRAND_SIZE,
      height: BRAND_SIZE,
      backgroundColor: 'brand-box-background',
    },
    'brand-box-lg': {
      width: BRAND_SIZE_HERO,
      height: BRAND_SIZE_HERO,
      flex: 0,
      flexShrink: 0,
      flexBasis: BRAND_SIZE_HERO,
    },
    'brand-box-sm': {
      width: BRAND_SIZE_SM,
      height: BRAND_SIZE_SM,
      backgroundColor: 'brand-box-background',
    },
    'brand-desc-bg-dark-bg': {
      ...BRAND_DESC_BG,
      ...DESC_BG_DARK,
    },
    'brand-desc-bg-gray-bg': {
      ...BRAND_DESC_BG,
      ...DESC_BG_GRAY,
    },
    'brand-desc-bg-lt-bg': {
      ...BRAND_DESC_BG,
      ...DESC_BG_LIGHT,
    },
    'brand-head-bg': {
      ...BRAND_HEAD_BG,
    },
    'brand-image': {
      ...IMAGE_COVER,
      width: '100%',
      height: BRAND_BANNER_HEIGHT,
      borderTopLeftRadius: BRAND_IMAGE_RADIUS,
      borderTopRightRadius: BRAND_IMAGE_RADIUS,
    },
    'brand-list': {
      flex: 1,
      width: '100%',
      alignItems: 'flex-start',
    },
    'brand-page-banner': {
      w: '100%',
      h: BRAND_PAGE_BANNER_PX,
      ...IMAGE_COVER,
      position: 'relative',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
    },
    'brand-page-brand-container': {
      border: '1px solid',
      borderColor: 'blackAlpha.400',
      borderRadius: '20px',
    },
    'brand-page-content': {
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'left',
      backgroundPositionY: 'top',
      backgroundSize: 'cover',
    },
    'brand-page-desc-bg': {
      width: '100%',
      px: [2, 2, 4],
      pb: 2,
      pt: 3,
      pointerEvents: 'none',
    },
    'brand-page-head': {
      ...HEAD_HERO_BG_LIGHT,
      pt: [2, 3, 4],
      px: [4, 5, 6],
    },
    'brand-page-logo': {
      ...IMAGE_COVER,
      zIndex: 10,
      w: BRAND_PAGE_LOGO_PX,
      h: BRAND_PAGE_LOGO_PX,
      border: '3px solid white',
      shadow: 'md',
    },
    'brand-page-logo-wrapper': {
      position: 'relative',
      w: '100%',
      h: '1px',
      overflow: 'show',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      mb: `${BRAND_PAGE_LOGO_HEIGHT / 2 + 13}px`, // 10px padding, 3px border
    },
    'brand-page-text-box': {
      px: [4, 5, 6],
      py: [2, 3, 4],
      pt: [8, 9, 10],
      pl: 0,
    },
    'brand-page-text-box-inner': {
      maxWidth: TEXTBOX_MAX_WIDTH,
      ml: [0, 0, 0, '80px'],
    },
    'brand-text': {
      p: BRAND_PADDING,
      borderBottomLeftRadius: BRAND_IMAGE_RADIUS,
      borderBottomRightRadius: BRAND_IMAGE_RADIUS,
      border: '1px solid',
      borderColor: 'blackAlpha.300',
    },
    carousel: {
      ...IMAGE_COVER,
      mt: 6,
      width: '100%',
      position: 'relative',
      backgroundColor: 'transparent',
      height: '180px',
      overflow: 'hidden',
    },
    'carousel-background': {
      ...IMAGE_COVER,
      ...CAROUSEL,
      zIndex: 5,
    },
    'carousel-prev': {
      ...IMAGE_COVER,
      ...CAROUSEL,
      zIndex: 10,
    },
    categories: {
      px: 2,
      py: 1,
      display: 'block',
      borderBottomWidth: '1px',
      borderColor: 'blackAlpha.300',
      borderBottomStyle: 'solid',
      mb: 3,
    },
    contained: {
      p: 0,
      m: 0,
      h: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'stretch',
      alignItems: 'stretch',
    },
    'field-label': {
      alignItems: 'flex-start',
    },
    'form-buttons': {
      pt: [4, 4, 6],
      my: [2, 2, 3],
      maxWidth: ['100%', '100%', FORM_MAX_WIDTH],
    },
    'form-buttons-inner': {
      py: [1, 1, 1],
      flex: 1,
      fontSize: 'lg',
    },
    header: {
      w: '100%',
      py: 0,
      justifyContent: 'space-between',
      backgroundColor: 'header-bg',
      h: ['88px', '88px', '93px', '116px'],
      px: ['20px', '20px', '40px', '104px'],
      pb: ['10px', '10px', '10px', 0],
      alignItems: ['flex-end', 'flex-end', 'flex-end', 'center'],

      flexDirection: 'row',
      display: 'flex',
    },
    'header-container': {
      zIndex: 100,
    },
    'hero-desc-bg': {
      width: '100%',
      pr: '3rem',
      pb: 6,
      pt: 4,
      pointerEvents: 'none',
    },
    'hero-head-bg': {
      width: '100%',
      py: 4,
      pointerEvents: 'none',
      textAlign: 'left',
    },
    'hero-offer': {
      width: '100%',
      flex: 0,
      height: '250px',
      flexBasis: '250px',
      backgroundColor: 'brand-box-background',
      position: 'relative',
    },
    'input-row': {
      maxWidth: '900px',
      my: [2, 2, 3],
    },
    'input-row-field': {
      px: [1, 1, 2],
      py: [1, 1, 1],
      flex: 1,
      fontSize: 'lg',
      position: 'relative',
      width: '100%',
    },

    'input-row-field-small': {
      px: [4, 4, 6],
      py: [1, 1, 1],
      flex: 1,
      fontSize: 'md',
      position: 'relative',
      width: '100%',
    },

    'input-row-label': {
      width: ['200px', '200px', '250px'],
      px: [1, 1, 2],
      py: [1, 1, 1],
      flexBasis: ['200px', '200px', '250px'],
      flex: 0,
      fontSize: 'lg',
    },
    'input-row-label-small': {
      mt: [2, 2, 3],
      px: [4, 4, 6],
      py: [1, 1, 1],
      display: 'block',
      textAlign: 'center',
      flexBasis: ['200px', '200px', '250px'],
      flex: 0,
      fontSize: 'md',
    },
    'landing-page-brand-row-grid': {
      width: '100%',
      bottom: '95px',
      position: 'absolute',
      rowGap: 0,
      zIndex: 30,
      alignItems: 'center',
    },
    'landing-page-container': {
      position: 'absolute',
      top: 0,
      w: '100%',
      height: '100%',
      pt: '110px',
    },
    'landing-page-intro-text': {
      width: ['100%', 'min(600px, 50vw)'],
      flexBasis: 'min(600px, 50vw)',
      ml: ['20px', '100px', '235px'],
    },
    'landing-page-intro-text-panel-3': {
      width: ['100%', ' max(800px, 70vw)'],
      flex: 1,
      flexBasis: ['auto', 'auto', 'min(600px, 50vw)'],
      ml: [0, 0, '180px'],
    },
    'landing-page-one': {
      bgGradient: 'linear(to-bl, lp.1, lp.2, lp.3)',
      height: 'calc(100vh-130px)',
      w: '100%',
    },
    'landing-page-panel': {
      w: '100%',
    },
    'logo-icon': {
      ...IMAGE_COVER, // should be IMAGE_CONTAIN - when live, if logo is not square
      height: '100%',
      width: '100%',
    },
    'lp-button': {
      backdropFilter: 'blur(15px)',
      backgroundColor: 'blackAlpha.500',
      border: '1px solid white',
      borderRadius: '1.5em',
      position: 'absolute',
      px: 8,
      py: 2,
    },
    'modal-field-row': {
      my: 2,
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    'modal-sm': {
      maxWidth: '340px',
      borderRadius: '200px',
    },

    'modal-sm-header': {
      borderTopLeftRadius: '200px',
      borderTopRightRadius: '20px',
    },
    offer: {
      ...IMAGE_COVER,
      borderWidth: 1,
      borderColor: 'blackAlpha.300',
      mx: 1,
      my: 0.5,
      px: 3,
      py: 1,
    },

    'offer-arrows': {
      w: '100%',
      h: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
      position: 'absolute',
      zIndex: 2000,
    },
    'offer-arrows-brand': {
      w: '100%',
      h: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
      position: 'absolute',
      zIndex: 2000,
    },
    'offer-container': {
      w: '100%',
      justifyContent: 'center',
      p: '20px',
      position: 'relative',
    },
    'offer-container-overlay': {
      p: '20px',
      position: 'absolute',
      zIndex: 100,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backdropFilter: 'blur(20px)',
    },

    'offer-desc-bg-dark-bg': {
      ...OFFER_DESC_BG,
      ...DESC_BG_DARK,
    },
    'offer-desc-bg-gray-bg': {
      ...OFFER_DESC_BG,
      ...DESC_BG_GRAY,
    },
    'offer-desc-bg-lt-bg': {
      ...OFFER_DESC_BG,
      ...DESC_BG_LIGHT,
    },
    'offer-head-bg-dark-bg': {
      ...OFFER_HEAD_BG,
      ...HEAD_BG_DARK,
    },
    'offer-head-bg-gray-bg': {
      ...OFFER_HEAD_BG,
      ...HEAD_BG_GRAY,
    },
    'offer-head-bg-lt-bg': {
      ...OFFER_HEAD_BG,
      ...HEAD_BG_LIGHT,
    },
    'page-container': {
      h: '100%',
      overflow: 'hidden',
    },
    'page-container-inner-margin': {
      flex: 1,
      maxWidth: PAGE_CONTAINER_INNER_MAX_WIDTH_PX,
      mx: ['20px', '20px', '40px'],
    },
    'page-frame': {
      w: '100%',
      h: '100%',
      overflow: 'hidden',
    },
    'page-frame-content': {
      w: '100%',
      overflow: 'hidden',
      flex: 1,
    },
    'prompt-bg': {
      p: 2,
      py: 4,
      backgroundColor: 'whiteAlpha.500',
      w: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    'prompt-over': {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      zIndex: 200,
      justifyContent: 'center',
      alignItems: 'center',
    },
    store: {
      position: 'relative',
    },
    'store-logo': {
      ...IMAGE_COVER,
      w: STORE_LOGO_SIZE_PX,
      h: STORE_LOGO_SIZE_PX,
      borderRadius: '50%',
    },

    'table-v-head': {
      py: '17px',
    },
    'text-block': {
      borderRadius: 3,
      shadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.10)',
      my: 10,
      px: '50px',
      py: '45px',
      pt: '31px',
    },

    'trans-cell': {
      py: '12px',
      m: 0,
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode('body-bg', 'body-bg')(props),
      },
    }),
  },
  textStyles: {
    bigger: { fontSize: '1.5em', m: 0, p: 0 },
    'brand-offer-desc': {
      fontSize: '20px',
      fontFamily: 'display',
      fontWeight: 400,
    },
    'brand-page-desc': {
      fontSize: ['0.8rem', '0.85rem', '0.9rem'],
    },
    'carousel-desc': {
      color: 'white',
      fontSize: '14px',
      textShadow: '1px 1px 1px rgba(0,0,0,1);',
      noOfLines: 2,
      maxWidth: '500px',
      my: 4,
    },
    'field-label': {
      fontFamily: 'display',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      textAlign: 'left',
      color: 'blackAlpha.400',
      fontWeight: 300,
    },
    'header-text': {
      fontFamily: 'display',
      fontSize: '16px',
    },
    'hero-desc': {
      fontSize: '18px',
      textAlign: 'left',
      fontWeight: 700,
      noOfLines: 5,
      pl: 2,
    },
    'hero-header': {
      ...HERO_HEAD_TXT,
      ...MAJOR_HEAD,
    },
    'hero-header-brand': {
      ...HERO_HEAD_TXT,
      ...HERO_HEAD_BRAND,
    },
    minor: {
      fontSize: '12px',
      color: 'blackAlpha.600',
      fontFamily: 'display',
      textAlign: 'center',
    },
    'landing-page-intro': {
      fontSize: '24px',
      lineHeight: '25px',
      fontWeight: 500,
      color: 'brand-tertiary',
      my: 7,
    },
    'landing-page-gs-txt': {
      color: 'secondary-primary',
      fontSize: ['16px', '18px', '24px'],
      textAlign: 'center',
    },
    'lp-button': {
      color: 'white',
      fontWeight: 700,
      fontSize: '20px',
    },
    'modal-info': {
      fontSize: '16px',
      textAlign: 'center',
    },
    'offer-desc': {
      ...OFFER_DESC,
      color: 'offer-desc',
    },
    'offer-page-desc': {
      fontSize: ['0.8rem', '0.85rem', '0.9rem'],
    },
    'store-offer': {
      fontFamily: 'display',
      fontSize: '20px',
      textAlign: 'center',
    },
    'text-block': {
      fontSize: '16px',
      fontFamily: 'display',
      color: 'blackAlpha.800',
      pt: '10px',
    },
    'trans-cell': {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    'table-v-head': {
      textAlign: 'left',
      color: 'blackAlpha.400',
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
  },
});
