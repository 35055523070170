import { BRAND_ID_KEY } from '~/lib/constants.js';
import dayjs from 'dayjs';

export function brandLink(brandOrId) {
  if (!brandOrId) return null;
  if (typeof brandOrId === 'number') return brandLink(`${brandOrId}`);
  if (typeof brandOrId !== 'string') {
    if (!(typeof brandOrId === 'object') && BRAND_ID_KEY in brandOrId) {
      console.error('bad submit to brandLink', brandOrId);
      return null;
    }
    return brandLink(brandOrId[BRAND_ID_KEY]);
  }

  return '/brands/' + encodeURIComponent(brandOrId);
}

const catNameAliases = new Map([
  ['office & business', 'office-and-business'],
  ['home & diy', 'home-and-diy'],
  ['gift cards', 'gift-cards'],
  ['toys & games', 'toys-and-games'],
  ['mobile phones', 'mobile-phones'],
  ['food & drink', 'food-and-drink'],
  ['sports & fitness', 'sports-and-fitness'],
  ['health & beauty', 'health-and-beauty'],
]);

export function catName(category) {
  if (!category) {
    return 'blank';
  }
  const basis = category.toLowerCase();
  if (catNameAliases.has(basis)) return catNameAliases.get(basis);
  return basis;
}

export function plural(variants, test) {
  const { single, two, many, none } = variants;
  let count;
  if (typeof test === 'number') count = test;
  if (Array.isArray(test)) count = test.length;

  switch (count) {
    case 0:
      return 'none' in variants ? none : single;
      break;
    case 1:
      return single;
      break;
    case 2:
      return two ?? single;
      break;
    default:
      return many;
  }
}

export function shortDate(date, ifInvalid = '') {
  const d = dayjs(date);
  if (!d.isValid()) return ifInvalid;
  return d.format('YYYY/MM/DD');
}

export function longDate(date, ifInvalid = '') {
  const d = dayjs(date);
  if (!d.isValid()) return ifInvalid;
  return d.format('MMMM D, YYYY');
}

export function responseStatusIsGood(res) {
  if (!(res && typeof res === 'object')) {
    return false;
  }
  const s = Number(res.status) / 100;
  return Math.floor(s) === 2;
}

export function neutralizeEvent(e) {
  if (!e && e.preventDefault) return;
  e.preventDefault();
  e.stopPropagation();
}

export default function imgUrl(str) {
  if (str && typeof str === 'string') {
    return `url("${str}")`;
  }
  return '';
}
