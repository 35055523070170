import { useEffect } from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import useForest from '~/hooks/useForest.jsx';

import imgUrl from '~/lib/helpers.jsx';

const TRANS_DURATION = 500;
const PAUSE = 4000;

function NextPage({ state, value }) {
  const brand = state.$.currentBrand();
  return (
    <Box
      layerStyle='carousel-prev'
      style={{ left: state.$.left() }}
      backgroundImage={imgUrl(brand?.large_img_url)}
    >
      <Heading variant='carousel-head'>{brand?.name}</Heading>
      <Text variant='two-lines' textStyle='carousel-desc'>
        {brand?.description}
      </Text>
    </Box>
  );
}

export default function Carousel({ brands }) {
  const [value, state] = useForest(
    (brands) => {
      return {
        $value: {
          brands: brands || new Map(),
          active: true,
          currentIndex: 0,
          nextIndex: 0,
          startTime: 0,
          trans: 0,
        },
        selectors: {
          left(state) {
            const { trans } = state.value;
            const percent = `-${100 * trans}%`;
            return percent;
          },
          backgroundIndex(state) {
            const { currentIndex, nextIndex, trans } = state.value;
            return trans < 1 ? nextIndex : currentIndex;
          },
          backgroundBrand(state) {
            const { brands } = state.value;
            if (!brands.size) return null;
            const index = state.$.backgroundIndex();
            const key = Array.from(brands.keys())[index % brands.size];
            return brands.get(key);
          },
          backgroundImage(state) {
            return state.$.backgroundBrand()?.large_img_url;
          },
          currentBrand(state) {
            const { brands, currentIndex, nextIndex, trans } = state.value;
            if (!brands.size) return '';
            const key = Array.from(brands.keys())[currentIndex % brands.size];
            return brands.get(key);
          },
          currentImage(state) {
            return state.$.currentBrand()?.large_img_url;
          },
        },
        actions: {
          loadFrame(state, index) {
            if (!state.value.active) return;
            state.do.set_nextIndex(index);
            state.do.set_startTime(Date.now());
            requestAnimationFrame(state.do.advanceFrame);
          },
          nextFrame(state) {
            state.do.loadFrame(state.value.currentIndex + 1);
          },

          advanceFrame(state) {
            if (!state.value.active) return;
            let time = Date.now();
            const trans = (time - state.value.startTime) / TRANS_DURATION;

            if (trans > 1) {
              state.do.set_trans(1);
              state.do.set_currentIndex(state.value.nextIndex);
              setTimeout(state.do.nextFrame, PAUSE);
              return;
            }
            state.do.set_trans(trans);
            setTimeout(state.do.advanceFrame, 100);
          },
        },
      };
    },
    brands,
    {
      onCreate(state) {
        state.do.loadFrame(0);
      },
    }
  );

  useEffect(() => {
    if (state && brands) {
      state.do.set_brands(brands);
    }
  }, [brands, state]);

  useEffect(() => {
    return () => state?.do.set_active(false);
  }, []);

  if (!state) {
    return null;
  }

  const { trans } = value;
  if (!brands.length) return null;
  return (
    <Box layerStyle='carousel'>
      <Box layerStyle='carousel-background' backgroundImage={state.$.backgroundImage()}>
        <Heading variant='carousel-head'>{state.$.backgroundBrand().name}</Heading>
        <Text variant='two-lines' textStyle='carousel-desc'>
          {state.$.currentBrand()?.description}
        </Text>
        <Button variant='carousel'>View Offer</Button>
      </Box>
      {trans < 1 ? <NextPage state={state} value={value} /> : ''}
    </Box>
  );
}
