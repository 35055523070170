import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Input,
  useBoolean,
  VStack,
  Box,
  HStack,
} from '@chakra-ui/react';
import account from '~/lib/state/account-state.js';
import useGlobalForest from '~/hooks/useGlobalForest.jsx';
import { FaEye } from 'react-icons/fa';
import PasswordRow from '~/components/password-row.jsx';

const userForm = account.child('userForm');

export default function SignIn() {
  const { email } = useGlobalForest(userForm);
  const disabled = !userForm.$.canSignIn();
  return (
    <Modal isOpen onClose={account.do.resetMode}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mb={0} pb='10px' layerStyle='modal-sm-header'>
          Welcome
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={0}>
          <Text textStyle='modal-info'>Log in to see what’s on offer</Text>
          <VStack layerStyle='modal-field-row' alignItems='flex-start'>
            <Box layerStyle='field-label' data-role='field-label'>
              <Text textStyle='field-label'>eMail</Text>
            </Box>
            <Input
              name='email'
              value={email}
              placeholder='email'
              onChange={userForm.do.handleChange}
            />
          </VStack>
          <PasswordRow />
        </ModalBody>

        <ModalFooter>
          <Button
            width='100%'
            disabled={disabled}
            color={disabled ? 'accent-disabled-txt' : undefined}
            backgroundColor={disabled ? 'accent-disabled' : undefined}
            onClick={account.do.submitSignIn}
          >
            Sign In
          </Button>
        </ModalFooter>
        <Text textStyle='modal-info'>
          Don&apos;t have an account yet? <a onClick={account.do.startSignUp}>Sign Up</a>
        </Text>
      </ModalContent>
    </Modal>
  );
}
