import { chunk } from 'lodash';
import db from '~/lib/database/db.js';

import globalState from '~/lib/state/globall-state.js';
import accountState from '~/lib/state/account-state.js';

const BRANDS_API_URL = import.meta.env.VITE_BRANDS_API_URL;

/**
 * brands is a map of Brand instances, keyed by brand_uid;
 * categoryBrands is a map of ARRAYS of brand instances, keyed by category name.
 */
export function brandListState() {
  return {
    $value: {
      brands: new Map(),
      categoryBrands: new Map(),
      isLoading: false,
      more: true,
      category: '',
      brandColumns: 1,
      storeColumns: 3,
      nextPage: 0,
    },
    selectors: {
      chunks(state, chunkSize) {
        const items = state.$.list();
        return chunk(items, chunkSize);
      },
      baseList({ value: { brands, category, categoryBrands } }) {
        if (category) {
          return categoryBrands.get(category) || [];
        }
        return Array.from(brands.values());
      },
      list(state) {
        const ci = state.$.storeChunkIndexes();
        return state.$.baseList().filter((b) => !ci.includes(b.brand_uid));
      },
      storeChunkIndexes(state) {
        return state.$.baseList()
          .slice(0, state.value.storeColumns)
          .map((b) => b.brand_uid);
      },
      multiBrandsOnRow(state) {
        const { brandColumns } = state.value;
        return brandColumns > 1;
      },

      brandList(state) {
        const { brandColumns } = state.value;
        if (brandColumns > 1) return state.$.chunks(brandColumns);
        return state.$.list();
      },
      storeChunk(state) {
        return state.$.storeChunkIndexes().map((id) => state.value.brands.get(id));
      },
    },
    actions: {
      async updateCategory(state, category) {
        if (!state.value.categoryBrands.has(category)) {
          state.do.loadCategory(category);
        }
        state.do.set_category(category);
      },
      async loadCategory(state, category) {
        /**
         * will be an API soon; for now,
         * we are pulling ALL THE BRANDS and filtering out the category we want.
         * (yes we can do all at once but this is "demo code" and will all go away anyway).
         */
        state.do.set_isLoading(true);
        db.then(async (database) => {
          const allBrands = await database.brands.find().exec();
          const newCategoryBrands = new Map(state.value.categoryBrands);
          const brandsInCat = allBrands
            .filter((brand) => brand.category && brand.category.category === category)
            .map((brand) => brand.toJSON());
          console.log('loaded category', category, 'brands:', brandsInCat);
          newCategoryBrands.set(category, brandsInCat);
          state.do.set_categoryBrands(newCategoryBrands);
          state.do.set_isLoading(false);
        });
      }, // DEPRECATED -- revise
      /**
       * mix in a new batch of brands with the already loaded records
       * @param state
       * @param brands
       */
      addBrands(state, brands) {
        let map = new Map(state.value.brands);
        brands.forEach((brand) => {
          let info = brand;
          if ('toJSON' in brand) info = brand.toJSON();
          return map.set(brand.brand_uid, info);
        });
        state.do.set_brands(map);
        state.do.set_isLoading(false);
      },
      async fetchBrands(state) {
        if (!state.value.more) return;
        const database = await db;
        try {
          const { nextPage } = state.value;
          const reqHeaders = await accountState.do.headersAsync();

          const response = await fetch(`${BRANDS_API_URL}?page=${nextPage}&size=30`, {
            method: 'GET',
            headers: reqHeaders,
          });
          const data = await response.json();
          const { content, number, totalPages } = data;
          for (const brand of content) {
            if (Array.isArray(brand.offers)) {
              for (const offer of brand.offers) {
                await database.offers.incrementalUpsert({
                  ...offer,
                  brand_uid: brand.brand_uid,
                });
              }
            }
            delete brand.offers;
          }
          await database.brands.bulkInsert(content);
          state.do.addBrands(content);
          state.do.set_more(totalPages > number + 1);
          state.do.set_nextPage(number + 1);
        } catch (fetchErr) {
          const toast = globalState.getMeta('toast');
          if (toast) {
            toast({
              title: 'Cannot fetch brands',
              description: fetchErr.message,
            });
          }
          console.error('brands fetch error:', fetchErr);
        }
      },
      loadBrands(state) {
        const { isLoading, more } = state.value;
        if (isLoading || !more) {
          return;
        }
        state.do.set_isLoading(true);
        state.do.fetchBrands();
      },
    },
  };
}
