import account from '~/lib/state/account-state.js';
import { useEffect, useState } from 'react';

export default function useIsLoggedIn() {
  const [isLoggedIn, setIsLoggedIn] = useState(account.value.isLoggedIn);
  useEffect(() => {
    const sub = account.subscribe((value) => {
      setIsLoggedIn(value.isLoggedIn);
    });

    return () => {
      sub?.unsubscribe();
    };
  }, []);

  return isLoggedIn;
}
