import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys
);

const account = definePartsStyle({
  field: {
    fontSize: 'lg',
    px: 2,
    py: 4,
    border: '2px solid',
    borderColor: 'lime.300',
    backgroundColor: 'blackAlpha.100',
    _focus: {
      backgroundColor: 'blackAlpha.50',
      borderColor: 'black',
    },
  },
  addon: {
    fontSize: 'lg',
    backgroundColor: 'whiteAlpha.100',
  },
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle: {
    field: {
      border: '1px solid whiteAlpha.400',
    },
  },
  variants: { account },
});
