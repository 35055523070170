import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';
import React from 'react';

import '../App.css';
import BrandsPage from '~/pages/brands/brands-page.jsx';
import PageLayout from '../components/page-layout.jsx';
import { offerLoader } from '~/pages/offer-loader.jsx';
import { brandLoader } from '~/pages/brand-loader.jsx';
import Chakra from '~/components/Chakra.jsx';
import LandingPage from '~/pages/landing/landing-page.jsx';
import { ErrorPage } from '~/pages/error-page.jsx';

const AccountPage = React.lazy(() => import('~/pages/account/account-page.jsx'));
const AccountCardsPage = React.lazy(() => import('~/pages/account/cards/account-cards-page.jsx'));
const OfferPage = React.lazy(() => import('./offer-page.jsx'));
const BrandPage = React.lazy(() => import('~/pages/brand/brand-page.jsx'));
const TermsPage = React.lazy(() => import('~/pages/terms/terms-page.jsx'));

const AccountCardsPageSuspended = (props) => (
  <React.Suspense fallback={<Spinner />}>
    <AccountCardsPage {...props} />
  </React.Suspense>
);
const AccountPageSuspended = (props) => (
  <React.Suspense fallback={<Spinner />}>
    <AccountPage {...props} />
  </React.Suspense>
);

const BrandPageSuspended = (props) => (
  <React.Suspense fallback={<Spinner />}>
    <BrandPage {...props} />
  </React.Suspense>
);

const OfferPageSuspended = (props) => (
  <React.Suspense fallback={<Spinner />}>
    <OfferPage {...props} />
  </React.Suspense>
);

const TermsPageSuspended = () => (
  <React.Suspense fallback={<Spinner />}>
    <TermsPage />
  </React.Suspense>
);

const router = createBrowserRouter([
  {
    path: '*',
    element: <PageLayout />,
    children: [
      {
        path: '*',
        element: <LandingPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'brands',
        element: <BrandsPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'offers/:offer_uid',
        element: <OfferPageSuspended />,
        loader: offerLoader,
      },
      {
        path: 'account',
        element: <AccountPageSuspended />,
      },
      {
        path: 'account/cards',
        element: <AccountCardsPageSuspended />,
      },
      {
        path: 'brands/:brand_uid',
        element: <BrandPageSuspended />,
        loader: brandLoader,
      },
      {
        path: 'terms',
        element: <TermsPageSuspended />,
      },
    ],
  },
]);
function Router() {
  return (
    <Chakra>
      <RouterProvider router={router} />
    </Chakra>
  );
}

export default Router;
