import { Box, Card, CardBody, Heading, Skeleton, Stack, VStack } from '@chakra-ui/react';

import imgUrl from '~/lib/helpers.jsx';

export default function LandingSampleBrandCard({ brand, which }) {
  if (!which.showSample || !brand) return null;
  return (
    <Card data-role='sample card' width='240px' position='relative' overflow='show'>
      {!which.showButtons ? null : (
        <>
          <Box
            textStyle='lp-button'
            left='-230px'
            top='20px'
            layerStyle='lp-button'
            pointerEvents='none'
          >
            Spend a little. Live a lot.
          </Box>
          <Box
            textStyle='lp-button'
            right='-250px'
            bottom='20px'
            layerStyle='lp-button'
            pointerEvents='none'
            border='none'
            zIndex='20'
          >
            Earn up to 20% cash back
          </Box>
        </>
      )}
      <CardBody p={10}>
        <VStack justifyContent='center' w='100%'>
          <Box layerStyle='store-logo' backgroundImage={imgUrl(brand.logo_img_url)}></Box>
          <Heading>20%</Heading>
          <Stack width='100%'>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
        </VStack>
      </CardBody>
    </Card>
  );
}
