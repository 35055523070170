import { Forest } from '@wonderlandlabs/forest';
import { neutralizeEvent, responseStatusIsGood } from '~/lib/helpers.jsx';
import globalState from '~/lib/state/globall-state.js';
import { userFormState } from '~/lib/state/user-form-state.js';
import { ACCOUNT_MODE, DEFAULT_HEADERS } from '~/lib/constants.js';

let initialData = {};
let isLoggedIn = false;
let access_token = null;
let refresh_token = null;
let access_token_timeout = 0;

const AUTH_LIFESPAN = import.meta.env.VITE_AUTH_LIFESPAN; // lifespan of access_token, in seconds

if (window?.sessionStorage) {
  const user_email = window.sessionStorage.getItem('user_email');
  if (user_email) {
    isLoggedIn = true;

    initialData.access_token = window.sessionStorage.getItem('access_token');
    initialData.refresh_token = window.sessionStorage.getItem('refresh_token');
    initialData.access_token_timeout = window.sessionStorage.getItem('access_token_timeout');
    access_token = initialData.access_token ?? null;
    refresh_token = initialData.refresh_token ?? null;
    access_token_timeout = initialData.access_token_timeout || 0;
  }
}

// @TODO: date-stamp and auto-invalidate stale access tokens

const USER_URL = import.meta.env.VITE_USER_URL;
/**
 * this is the global "account status" state
 * to share  whether you are logged in across the site.
 *
 * It also has a child state for the user data,
 * used in login/signup forms
 */
const accountState = new Forest({
  $value: {
    data: initialData,
    access_token,
    access_token_timeout,
    refresh_token,
    isLoggedIn,
    error: null,
    publisherId: 'P1035',
    mode: ACCOUNT_MODE.neutral,
  },
  actions: {
    async refreshAuth(state) {
      const { refresh_token } = state.value;
      try {
        const response = await fetch(`${USER_URL}refresh-token`, {
          body: JSON.stringify({ refresh_token }),
          method: 'POST',
          headers: state.$.headers(),
        });
        const data = await response.json();

        const { access_token, expires_in } = data;
        if (!access_token) {
          throw new Error('cannot refresh auth');
        }

        state.do.set_access_token(access_token);
        state.do.set_access_token_timeout((expires_in || 5 * 60) * 1000 + Date.now());
        state.do.serializeLoggedInData();
      } catch (err) {
        globalState.do.message({
          title: 'Login has timed out',
          description: 'Your session has expired; please log in again',
        });
        state.do.signOut();
        throw err;
      }
    },
    async headersAsync(state, config) {
      const { access_token_timeout, access_token, refresh_token } = state.value;
      if (refresh_token && access_token && access_token_timeout) {
        if (access_token_timeout < Date.now()) {
          await state.do.refreshAuth(); // can throw, if refresh fails
        }
      }

      return state.$.headers(config);
    },
    signOut(state) {
      state.do.set_isLoggedIn(false);
      state.child('user').value = {};
      state.do.set_data({});
      state.do.set_access_token(null);
      if (window?.sessionStorage) {
        window.sessionStorage.clear();
      }
    },
    resetMode(state) {
      state.do.set_mode(ACCOUNT_MODE.neutral);
      state.child('userForm').do.reset();
    },
    serializeLoggedInData(state) {
      if (!window?.sessionStorage) {
        return;
      }
      const { sessionStorage } = window;
      const { user, access_token, refresh_token, access_token_timeout } = state.value;

      sessionStorage.setItem('user_email', user.email);
      sessionStorage.setItem('access_token', access_token);
      sessionStorage.setItem('refresh_token', refresh_token);
      sessionStorage.setItem('access_token_timeout', access_token_timeout);
    },
    startSignIn(state, e) {
      neutralizeEvent(e);
      state.do.set_mode(ACCOUNT_MODE.signingIn);
    },
    startSignUp(state, e) {
      neutralizeEvent(e);
      state.do.set_mode(ACCOUNT_MODE.signingUp);
    },
    async submitSignIn(state, e) {
      neutralizeEvent(e);
      const userForm = state.child('userForm');
      const userSubmitted = userForm.value;
      const toast = globalState.getMeta('toast');

      if (!userForm.$.canSignIn()) {
        if (toast) {
          toast({
            description: state.child('userForm').$.signUpError(),
            duration: 4000,
            status: 'error',
            title: 'Invalid email and/or password',
          });
        } else {
          console.error('attempt to sign in with bad un/pw');
        }
        return;
      }

      let body;
      try {
        body = userForm.$.signInBody();
      } catch (bodyErr) {
        if (toast) {
          toast({
            description: bodyErr.message,
            duration: 200,
            status: 'error',
            title: 'error forming request',
          });
        } else {
          console.error('signupBodyError:', bodyErr);
        }
        state.do.resetMode();
        return;
      }

      try {
        const response = await fetch(`${USER_URL}login`, {
          body: body,
          method: 'POST',
          headers: state.$.headers({ noAccessToken: true }),
        });

        if (!responseStatusIsGood(response)) {
          if (toast) {
            toast({
              title: 'Cannot sign in',
              description: 'bad status ' + response.status,
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
          }
          state.do.resetMode();
          return;
        } else {
          if (toast) {
            toast({
              title: 'Sign In Accepted',
              description: 'you are now signed in',
              status: 'success',
              duration: 4000,
              isClosable: true,
            });
          }
          const data = await response.json();
          state.do.set_data(data);
          const { access_token, refresh_token } = data;
          state.child('user').do.set_email(userSubmitted.email);
          state.do.set_isLoggedIn(true);
          const access_token_timeout = 1000 * (Number(AUTH_LIFESPAN) ?? 60 * 5) + Date.now();
          state.do.set_access_token_timeout(access_token_timeout);
          state.do.set_access_token(access_token);
          state.do.set_refresh_token(refresh_token);
          state.do.serializeLoggedInData();
        }

        state.do.resetMode();
      } catch (submitErr) {
        if (toast) {
          toast({
            title: 'Cannot sign up',
            description: submitErr.message,
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        } else {
          alert('Problem with sign-in:' + submitErr.message);
          console.error('bad sign in');
        }
        state.do.resetMode();
      }
    },
    async submitSignUp(state, e) {
      e?.preventDefault();
      e?.stopPropagation();
      const userForm = state.child('userForm');
      const toast = globalState.getMeta('toast');

      if (!userForm.$.canSignUp()) {
        console.error('attempt to sign up with bad un/pw');
        if (toast) {
          toast({
            description: state.child('userForm').$.signUpError(),
            duration: 4000,
            status: 'error',
            title: 'Invalid email and/or password',
          });
        } else {
          window.alert('Invalid email and/or password');
        }
        return;
      }
      let body;
      try {
        body = userForm.$.signUpBody();
      } catch (bodyErr) {
        if (toast) {
          toast({
            description: bodyErr.message,
            duration: 200,
            status: 'error',
            title: 'error forming request',
          });
        } else {
          window.alert('Cannot make request -- application error');
          console.error('signInBodyError:', bodyErr);
        }
        state.do.resetMode();
        return;
      }

      try {
        const response = await fetch(`${USER_URL}signup`, {
          body: body,
          method: 'POST',
          headers: state.$.headers({ noAccessToken: true }),
        });

        if (!responseStatusIsGood(response)) {
          if (toast) {
            //@TODO: move to global state with alternative handler
            toast({
              title: 'Cannot sign up',
              description: 'bad status ' + response.status,
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
          } else {
            window.alert('Cannot sign up');
            console.error('cannot sign up - ', response);
          }
          state.do.resetMode();
          return;
        } else {
          if (toast) {
            toast({
              title: 'Sign Up Accepted',
              description:
                'You should have received a validation e-mail from Finfare; follow its instructions to validate your account',
              status: 'success',
              duration: 10000,
              isClosable: true,
            });
          } else {
            window.alert('Sign up accepted; please sign in');
          }
        }

        state.do.resetMode();
      } catch (submitErr) {
        console.error('bad sign in');
        if (toast) {
          toast({
            title: 'Cannot sign up',
            description: submitErr.message,
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        } else {
          console.error('Cannot sign up ', submitErr);
          window.alert('Cannot sign up: ' + submitErr.message);
        }
        state.do.resetMode();
      }
    },
  },
  children: {
    user: {
      $value: {
        email: '',
      },
      actions: {},
    },
    userForm: userFormState,
    selectors: {},
  },
  name: 'account',
  selectors: {
    isExpired(state, threshold = 0) {
      const { access_token, access_token_timeout } = state.value;
      return access_token && access_token_timeout - threshold < Date.now();
    },
    headers(state, config) {
      let token = {};
      let headers = config?.headers ?? {};
      let noAccessToken = config?.noAccessToken ?? false;
      const { access_token, publisherId } = state.value;

      if (access_token && !noAccessToken) {
        token = { Authorization: `Bearer ${access_token}` };
      }
      return {
        ...DEFAULT_HEADERS,
        ...headers,
        ...token,
        'Publisher-Id': publisherId,
      };
    },
  },
});

if (accountState.value.isLoggedIn && accountState.$.isExpired()) {
  if (accountState.value.refresh_token) {
    accountState.do
      .refreshAuth()
      .then(() => {
        console.log('after refreshAuth state is ', accountState.value);
      })
      .catch((authErr) => {
        console.warn('attempt to auto-refresh failed: ', authErr.message);
        accountState.do.signOut();
      });
  } else {
    accountState.do.signOut();
  }
}

if (accountState.value.isLoggedIn && !accountState.$.isExpired()) {
  console.info(
    'account expires in:',
    Math.floor((accountState.value.access_token_timeout - Date.now()) / 1000),
    'seconds'
  );
}

export default accountState;
