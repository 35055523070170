import { Box, Heading, HStack, useBreakpointValue, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import {
  GettingStartedCol01,
  GettingStartedCol02,
  GettingStartedCol03,
} from '~/pages/landing/helpers.jsx';

export function LandingPage02GettingStarted() {
  const orientation = useBreakpointValue(
    {
      sm: 'vertical',
      md: 'vertical',
      lg: 'horizontal',
    },
    {
      default: 'md',
    }
  );
  let content = useMemo(() => {
    if (orientation === 'horizontal') {
      return (
        <HStack w='100%' justify='space-around'>
          <GettingStartedCol01 /> <GettingStartedCol02 /> <GettingStartedCol03 />
        </HStack>
      );
    }
    return (
      <VStack w='100%' alignItems='center'>
        <GettingStartedCol01 /> <GettingStartedCol02 /> <GettingStartedCol03 />
      </VStack>
    );
  }, [orientation]);

  return (
    <Box layerStyle='landing-page-panel' h={['auto', 'auto', 'auto', '100%']}>
      <Heading variant='landing-page-panel'>Getting started is easy</Heading>
      {content}
    </Box>
  );
}
