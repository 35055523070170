import { Box, Flex, Heading, HStack, Text, Tooltip, useBoolean, VStack } from '@chakra-ui/react';
import { BrandBox } from '~/components/brand-box.jsx';
import useBrand from '~/hooks/useBrand.js';
import useContrast from '~/hooks/useContrast.js';
import Arrows from '~/components/arrows.jsx';
import { Link } from 'react-router-dom';
import Color from 'color';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BuyPrompt from '~/pages/brands/buy-prompt.jsx';
import account from '~/lib/state/account-state.js';
import db from '~/lib/database/db.js';
import imgUrl, { brandLink, catName } from '~/lib/helpers.jsx';
import useIsLoggedIn from '~/hooks/useIsLoggedIn.jsx';

const JOIN_PROMPT = 'Join for cash back offers!';
const REDEEM_PROMPT = 'Redeem Offer';

const BackgroundImage = ({ brand }) => (
  <Box
    backgroundImage={imgUrl(brand.large_img_url)}
    layerStyle='bg-image'
    _hover={{
      backdropBlur: '4px',
    }}
  ></Box>
);

export function Brand({ brand, updateCategory = null, prompt = JOIN_PROMPT }) {
  const [inside, setFlag] = useBoolean(false);
  const isLoggedIn = useIsLoggedIn();
  const [offers, setOffers] = useState([]);
  const [offerCount, setOfferCount] = useState(0);
  useEffect(() => {
    let sub = null;
    let done = false;
    if (brand?.brand_uid) {
      db.then((database) => {
        sub = database.offers
          .find({
            selector: { brand_uid: brand.brand_uid },
          })
          .$.subscribe((offers) => {
            if (done) {
              sub?.unsubscribe();
            } else {
              if (isLoggedIn) {
                setOffers(offers);
              } else {
                setOffers([]);
              }
              setOfferCount(offers.length);
            }
          });
      });
    } else {
      setOffers([]);
    }

    return () => {
      done = true;
      sub?.unsubscribe();
    };
  }, [isLoggedIn, brand]);

  if (!brand) return null;

  return (
    <Link to={brandLink(brand)}>
      <Box
        key={brand.brand_uid}
        layerStyle='brand'
        data-role='brand'
        id={brand.brand_uid}
        onMouseEnter={setFlag.on}
        onMouseLeave={setFlag.off}
      >
        <Box layerStyle='brand-image' backgroundImage={imgUrl(brand.large_img_url)}></Box>
        <Box layerStyle='brand-text'>
          <Heading variant='brand-head'>{brand.name}</Heading>
          {offers.length ? (
            <Text variant='one-line' textStyle='brand-offer-desc'>
              {offers[0]?.name} {offerCount > 1 ? <small>{offerCount}</small> : null}
            </Text>
          ) : null}
        </Box>
        {inside ? <BuyPrompt prompt={isLoggedIn ? REDEEM_PROMPT : JOIN_PROMPT} /> : null}
      </Box>
    </Link>
  );
}
