'use client';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';

//👇 Import Open Sans font
import { theme } from '~/theme/theme';

export default function Chakra({ children }) {
  return (
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </>
  );
}
