export const SCHEMA_NUMBER = {
  type: 'number',
};
export const SCHEMA_STRING = {
  type: 'string',
};
export const SCHEMA_POS_NUMBER = {
  type: 'number',
  min: 0,
};

// note - as this is a PK for many tables, the maxLength is a required field.
export const SCHEMA_UUID = {
  type: 'string',
  maxLength: 6, // <- the primary key must have set maxLength
};
export const SCHEMA_LONG_UUID = {
  type: 'string',
  maxLength: 37, // <- the primary key must have set maxLength
};
export const SCHEMA_DATE = {
  type: 'string',
  pattern: '^d{4}-d{1,2}-d{1,2}.*',
};
export const SCHEMA_STATUS = {
  type: 'string',
  enum: ['ACTIVE', 'INACTIVE'],
  default: 'ACTIVE',
};
