'use client';
import { Box, useToast, VStack } from '@chakra-ui/react';
import Header from '~/components/header/header.jsx';
import { Outlet } from 'react-router';
import useGlobalForest from '~/hooks/useGlobalForest.jsx';
import account from '~/lib/state/account-state.js';
import { useEffect, useMemo } from 'react';
import SignUp from '~/components/sign-up.jsx';
import globalState from '~/lib/state/globall-state.js';
import SignIn from '~/components/sign-in.jsx';
import { ACCOUNT_MODE } from '~/lib/constants.js';

const TOAST_DEFAULT = {
  position: 'top',
  status: 'error',
  duration: 3000,
  isClosable: true,
};

export default function PageLayout() {
  const { mode } = useGlobalForest(account);
  const toast = useToast();

  useEffect(() => {
    globalState.setMeta('toast', (args) => toast({ ...TOAST_DEFAULT, ...args }), true);
  }, [toast]);

  let accountComponent = useMemo(() => {
    switch (mode) {
      case ACCOUNT_MODE.signingUp:
        return <SignUp />;
        break;

      case ACCOUNT_MODE.signingIn:
        return <SignIn />;
        break;
    }
    return null;
  }, [mode]);
  return (
    <>
      <VStack align='stretch' layerStyle='page-frame' as='article'>
        <Header />
        <Box flex={1} layerStyle='page-frame-content'>
          <Outlet />
        </Box>
      </VStack>
      {accountComponent}
    </>
  );
}
