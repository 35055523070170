import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const submit = defineStyle({
  background: 'lime.600',
  color: 'black',
  fontSize: 'lg',
  bgGradient: 'linear(to-b, gray.50, white, bangladesh-green-lt)',
});

const baseStyle = defineStyle(() => ({
  background: 'accent',
  colorScheme: 'accents',
  color: 'white',
  fontSize: '18px',
  borderRadius: '12px',
  flexShrink: 0,
  px: 3,
  py: 6,
  _hover: {
    bg: 'accent-lt',
  },
}));

const outline = defineStyle({
  background: 'white',
  colorScheme: 'white',
  color: 'persian-green',
  border: '1px solid',
  borderColor: 'persian-green',
  _hover: {
    backgroundColor: 'brand-tertiary',
  },
});

const submitDisabled = defineStyle({
  background: 'gray.200',
  color: 'white',
});
const secondary = defineStyle({
  color: 'persian-green',
  backgroundColor: 'secondary-green',
});

const major = defineStyle({
  px: '18x',
  py: '30px',
});

const carousel = defineStyle({
  position: 'absolute',
  right: '50px',
  bottom: '30px',
  zIndex: 10,
});

export const buttonTheme = defineStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'accents',
  },
  variants: {
    submit,
    secondary,
    major,
    outline,
    carousel,
    'submit-disabled': submitDisabled,
  },
});
