import { Box, HStack, Input, Text, useBoolean, VStack } from '@chakra-ui/react';
import { FaEye } from 'react-icons/fa';
import account from '~/lib/state/account-state.js';
import useGlobalForest from '~/hooks/useGlobalForest.jsx';

const userForm = account.child('userForm');

export default function PasswordRow() {
  const { password } = useGlobalForest(userForm);
  const [showPW, showPWctrl] = useBoolean(false);
  return (
    <VStack layerStyle='modal-field-row' alignItems='flex-start'>
      <Box layerStyle='field-label' data-role='field-label' w='100%'>
        <HStack w='100%' justify='space-between'>
          <Text textStyle='field-label'>Password</Text>
          <Text color='blackAlpha.500'>
            <FaEye onClick={showPWctrl.toggle} />
          </Text>
        </HStack>
      </Box>
      <Input
        name='password'
        type={showPW ? 'text' : 'password'}
        value={password}
        placeholder='password'
        onChange={userForm.do.handleChange}
      />
    </VStack>
  );
}
