import { MIN_CHARS } from '~/lib/constants.js';

export const userFormState = {
  $value: {
    email: '',
    password: '',
  },
  actions: {
    handleChange(state, event) {
      if (event?.target?.name) {
        const { name, value } = event.target;
        state.set(name, value);
      }
    },
    reset(state) {
      state.do.set_email('');
      state.do.set_password('');
    },
  },
  selectors: {
    canSignUp(state) {
      return !state.$.signUpError();
    },
    canSignIn(state) {
      return !state.$.signInError();
    },
    signUpError(state) {
      return state.$.emailError() || state.$.passwordError();
    },
    signInError(state) {
      return state.$.emailError() || state.$.passwordError();
    },
    emailError(state) {
      if (!state.value.email) return 'required';
      if (!/^.+@.{2,}\.\w{2,}$/.test(state.value.email)) {
        return 'invalid email';
      }
      return null;
    },
    passwordError(state) {
      if (state.value.password?.length < MIN_CHARS) {
        return 'password too short';
      }
      return null;
    },
    signInBody(state) {
      const { email, password } = state.value;
      return JSON.stringify({ email, password });
    },
    signUpBody(state) {
      const { email, password } = state.value;
      return JSON.stringify({
        email,
        password,
        first_name: 'foo',
        last_name: 'bar',
      });
    },
  },
};
