'use client';

import { addRxPlugin, createRxDatabase } from 'rxdb';
import { getRxStorageMemory } from 'rxdb/plugins/storage-memory';
import { shuffle } from 'lodash';
import { v4 } from 'uuid';
import { LoremIpsum } from 'lorem-ipsum';
import { ART_CATEGORIES, CATEGORIES, DEFAULT_HEADERS } from '~/lib/constants.js';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import brandFields from '~/lib/database/schema/brand-fields.js';
import offerFields from '~/lib/database/schema/offer-fields.js';
import globalState from '~/lib/state/globall-state.js';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import account from '~/lib/state/account-state.js';

const BRANDS_API_URL = import.meta.env.VITE_BRANDS_API_URL;
const BRANDS_AUTH = import.meta.env.VITE_BRANDS_AUTH;

addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBDevModePlugin);
addRxPlugin(RxDBQueryBuilderPlugin);

function randInt(max, min = 0) {
  return Math.min(max, Math.max(Math.floor(Math.random() * (max - min + 1)), min));
}

async function getPexelPhotos(term) {
  term = term.replace(/\W+/g, ',');
  const response = await fetch('/api/pexel/' + encodeURIComponent(term));
  const { data } = await response.json();
  return data;
}

const artCache = new Map();

async function getArt(term) {
  const newArt = await getPexelPhotos(term);
  artCache.set(term, newArt);
  return newArt.pop();
}

const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 5,
    min: 2,
  },
  wordsPerSentence: {
    max: 10,
    min: 3,
  },
});

/**
 *
 * Compatibility note - the records returned from fetch are an array of RxDocuments;
 * these have self-referencing loops and so cannot be directly added to Forest fields.
 * Before adding a RxDocument to Forest,
 * serialize it using `myDoc.toJSON()`
 * to eject a storeable POJO version of the data in a record.
 */

export async function getDatabase() {
  const myDatabase = await createRxDatabase({
    name: 'white-label',
    storage: getRxStorageMemory(),
  });

  const offerSchema = {
    schema: {
      version: 1,
      primaryKey: 'offer_uid',
      type: 'object',
      properties: offerFields,
      required: [
        'offer_uid',
        'status',
        'name',
        'description',
        'terms_and_conditions',
        'description',
      ],
    },
    statics: {
      async makeRandom(brand_uid) {
        const category = ART_CATEGORIES[Math.floor(Math.random() * ART_CATEGORIES.length)]; //@TODO: use brand category
        const bgItem = await getArt(category.toLowerCase());
        if (!(bgItem && bgItem.src)) {
          console.error('cannot make random offer from ', category, 'no art found');
          return;
        }
        const newOffer = {
          offer_uid: v4().substring(0, 6),
          name: lorem.generateWords(randInt(6, 1)),
          description: category + ' ' + lorem.generateParagraphs(randInt(5, 1)),
          banner_url: bgItem.src.large,
          terms_and_conditions: lorem.generateParagraphs(randInt(5, 1)),
          status: 'ACTIVE',
          brand_uid: brand_uid,
        };
        const newRecord = await this.incrementalUpsert(newOffer);
        return newRecord.toJSON();
      },
    },
    migrationStrategies: {
      1: function (doc) {
        return doc;
      },
    },
  };

  const brandSchema = {
    statics: {
      async fetch(page = 0, size = 100, reload = false) {
        // don't reload the same dataset unless explicitly requested
        if (!reload) {
          const existing = await this.find().exec();
          const existingCount = existing.length;
          if (existingCount >= size * (page + 1)) {
            return;
          }
        }

        const response = await fetch(`${BRANDS_API_URL}?page=${page}&size=${size}`, {
          method: 'GET',
          headers: account.$.headers({ noAccessToken: true }),
        });

        const data = await response.json();

        const { content } = data;
        for (const brand of content) {
          if (Array.isArray(brand.offers)) {
            for (const offer of brand.offers) {
              await this.database.offers.incrementalUpsert({
                ...offer,
                brand_uid: brand.brand_uid,
              });
            }
          }
          delete brand.offers;
        }
        const { success, error } = await this.bulkInsert(content);
        if (error.length) {
          console.log('error upserting', content, ...error);
        }
        return success ?? [];
      },
      async makeRandom() {
        const category = shuffle(CATEGORIES).pop();
        const artItem = await getArt(category.category);
        const bgItem = await getArt(category.category);
        const logoImage = artItem.src.small;
        const largeImage = bgItem.src.medium;

        let nextBrand = {
          brand_uid: v4().substring(0, 6),
          name: lorem.generateWords(randInt(6, 1)),
          description: lorem.generateParagraphs(randInt(5, 1)),
          status: 'ACTIVE',
          large_img_url: largeImage,
          logo_img_url: logoImage,
          publisher_categories: [],
          category,
        };
        return this.incrementalUpsert(nextBrand);
      },
    },
    schema: {
      version: 1,
      primaryKey: 'brand_uid',
      type: 'object',
      properties: brandFields,
      required: [
        'brand_uid',
        'name',
        'small_img_url',
        'large_img_url',
        'logo_img_url',
        'publisher_categories',
      ],
    },
    migrationStrategies: {
      1: function (doc) {
        return doc;
      },
    },
  };

  await myDatabase.addCollections({
    offers: offerSchema,
    brands: brandSchema,
  });

  // await myDatabase.offers.bulkInsert(offers);
  // await myDatabase.brands.bulkInsert(brands);

  return myDatabase;
}

const db = getDatabase();

export default db;
