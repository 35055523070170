import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Input,
  useBoolean,
  VStack,
  Box,
  CardBody,
  CardFooter,
  CardHeader,
  Card,
} from '@chakra-ui/react';
import account from '~/lib/state/account-state.js';
import useGlobalForest from '~/hooks/useGlobalForest.jsx';
import PasswordRow from '~/components/password-row.jsx';

const userForm = account.child('userForm');

export default function SignUpFixed() {
  const { email } = useGlobalForest(userForm);
  const disabled = !userForm.$.canSignUp();
  return (
    <Card>
      <CardHeader pb='10px' mb={0} layerStyle='modal-sm-header'>
        Get Started
      </CardHeader>
      <CardBody pt={5} px={8}>
        <Text textStyle='modal-info'>Free forever, no credit card needed</Text>
        <VStack layerStyle='modal-field-row' alignItems='flex-start'>
          <Box layerStyle='field-label' data-role='field-label'>
            <Text textStyle='field-label'>Email</Text>
          </Box>
          <Input
            name='email'
            value={email}
            placeholder='email'
            onChange={userForm.do.handleChange}
          />
        </VStack>
        <PasswordRow />
      </CardBody>
      <CardFooter>
        <Button
          width='100%'
          disabled={disabled}
          color={disabled ? 'accent-disabled-txt' : undefined}
          backgroundColor={disabled ? 'accent-disabled' : undefined}
          onClick={account.do.submitSignUp}
        >
          Sign Up
        </Button>
      </CardFooter>
    </Card>
  );
}
