'use client';

import { Box, Text, WrapItem, useBreakpointValue, HStack } from '@chakra-ui/react';

import Logo from '~/components/logo.jsx';
import { useEffect, useState } from 'react';
import account from '~/lib/state/account-state.js';
import { Link } from 'react-router-dom';
import User from '~/components/header/user.jsx';
import Search from '~/components/search.jsx';

export default function Header() {
  const logoProps = useBreakpointValue(
    {
      sm: { width: '112px', height: '31px' },
      md: { width: '112px', height: '31px' },
      lg: {},
      xl: {},
    },
    { fallback: 'md' }
  );

  const sizingProps = useBreakpointValue(
    {
      sm: {
        showUser: false,
        showCategory: false,
        showSearch: false,
        userProps: {},
      },
      md: {
        showUser: true,
        showCategory: true,
        showSearch: false,
        userProps: { small: true },
      },
      lg: {
        showUser: true,
        showCategory: true,
        showSearch: true,
        userProps: {},
      },
    },
    { fallback: 'md' }
  );

  return (
    <Box layerStyle='header-container' as='section'>
      <Box layerStyle='header' as='header'>
        <WrapItem flexbasis='154px'>
          <Link to='/brands'>
            <Logo {...logoProps} />
          </Link>
        </WrapItem>
        {sizingProps.showSearch ? <Search width='400px' /> : null}
        <HStack spacing={8}>
          {sizingProps.showCategory ? (
            <HStack spacing={2} alignItems='center'>
              <img alt='icon' src='/icons/categories-icon.svg' />
              <Text textStyle='header-text'>Categories</Text>
              <img alt='dropdoqwn' src='/icons/categories-dropdown.svg' />
            </HStack>
          ) : null}

          {sizingProps.showUser ? <User {...sizingProps.userProps} /> : null}
        </HStack>
      </Box>
      {sizingProps.showSearch ? null : <Search width='400px' />}
    </Box>
  );
}
