import { useEffect, useRef, useState } from 'react';
import { Forest } from '@wonderlandlabs/forest';

export default function useGlobalForest(forest) {
  // listens to a shared state, simply copies its value out
  const [value, setValue] = useState(forest.value);
  useEffect(() => {
    const sub = forest.subscribe(setValue);
    return () => sub?.unsubscribe();
  }, [forest]); // deliberately not using def; only want this to run once whatever.

  return value;
}
