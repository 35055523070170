import { Box, Text, useBoolean, VStack } from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import BuyPrompt from './buy-prompt.jsx';
import imgUrl from '~/lib/helpers.jsx';

const JOIN_PROMPT = 'Join for cash back offers!';

export function Store({ brand, prompt = JOIN_PROMPT }) {
  const [inside, setFlag] = useBoolean(false);

  if (!brand) return null;

  return (
    <Link to={'/brands/' + encodeURIComponent(brand.brand_uid)}>
      <Box
        key={brand.brand_uid}
        layerStyle='store'
        data-role='store'
        id={brand.brand_uid}
        onMouseEnter={setFlag.on}
        onMouseLeave={setFlag.off}
      >
        <VStack justifyContent='center'>
          <Box layerStyle='store-logo' backgroundImage={imgUrl(brand.logo_img_url)}></Box>
          <Text textStyle='store-offer'>cashback</Text>
          {inside ? <BuyPrompt prompt={prompt} /> : null}
        </VStack>
      </Box>
    </Link>
  );
}
