import { Box, VStack } from '@chakra-ui/react';
import useForest from '~/hooks/useForest.jsx';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import BrandToken from '~/pages/landing/brand-token.jsx';
import { LandingPage01Intro } from '~/pages/landing/landing-page-01-intro.jsx';
import * as PropTypes from 'prop-types';
import { landingPageState } from '~/pages/landing/landing-page-state.jsx';
import { LandingPage02GettingStarted } from '~/pages/landing/landing-page-02-getting-started.jsx';
import { LandingPage03SignUp } from '~/pages/landing/landing-page-03-sign-up.jsx';
import { GettingStartedColumn } from '~/pages/landing/helpers.jsx';

const countFromEle = (ele) => {
  if (!ele) return 10;
  return Math.min(10, Math.floor(ele.clientWidth / 180));
};

BrandToken.propTypes = {};

GettingStartedColumn.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default function LandingPage() {
  const [value, state] = useForest(landingPageState, null, {
    onCreate(state) {
      state.do.load();
    },
  });
  const { brands, brandCount } = value;

  const resizeObserver = useRef(null);
  const watchSize = useCallback(
    (ele) => {
      if (!state) return;
      if (ele) {
        state.do.set_brandCount(countFromEle(ele));

        if (resizeObserver.current) {
          // shouldn't happen but if this loop gets hit more than once clean up the last
          // execution loop's work
          resizeObserver.current.disconnect();
        }
        resizeObserver.current = new ResizeObserver(() => {
          state.do.set_brandCount(countFromEle(ele));
        });

        resizeObserver.current.observe(ele);
      } else {
        state.do.set_brandCount(10);
      }
    },
    [state]
  );

  useEffect(() => {
    resizeObserver.current?.disconnect();
  }, []);

  const imgBrands = useMemo(() => {
    return brands?.slice(0, brandCount) ?? [];
  }, [brandCount, brands]);

  if (!state) return null;
  return (
    <VStack
      layerStyle='landing-page-container'
      data-role='landing-page-container'
      alignItems='stretch'
      p={0}
      m={0}
    >
      <Box overflowY='scroll' h='100%' ref={watchSize}>
        <LandingPage01Intro brands={brands} imgBrands={imgBrands} state={state} />
        <LandingPage02GettingStarted />

        <LandingPage03SignUp imgBrands={imgBrands} state={state} />
      </Box>
    </VStack>
  );
}
