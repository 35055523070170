import db from '~/lib/database/db.js';

export function landingPageState() {
  return {
    $value: {
      brands: [],
      brandCount: 10,
    },
    actions: {
      async load(state) {
        const database = await db;
        try {
          await database.collections.brands.fetch(0, 10);
          const brands = await database.collections.brands.find().limit(10).exec();
          state.do.set_brands(brands.map((b) => b.toJSON()));
        } catch (fetchErr) {
          console.error(fetchErr.message, fetchErr);
        }
      },
    },
  };
}
