import {
  SCHEMA_LONG_UUID,
  SCHEMA_STATUS,
  SCHEMA_STRING,
  SCHEMA_UUID,
} from '~/lib/database/data-helpers.js';

export default {
  brand_uid: SCHEMA_UUID,
  category: {
    type: 'object',
    properties: {
      category_id: SCHEMA_STRING,
      category: SCHEMA_STRING,
    },
  },
  name: SCHEMA_STRING,
  website: SCHEMA_STRING,
  description: SCHEMA_STRING,
  mid: SCHEMA_STRING,
  logo_img_url: SCHEMA_STRING,
  large_img_url: SCHEMA_STRING,
  small_img_url: SCHEMA_STRING,
  contact_first_name: SCHEMA_STRING,
  contact_last_name: SCHEMA_STRING,
  contact_phone_number: SCHEMA_STRING,
  contact_email: SCHEMA_STRING,
  contact_address_1: SCHEMA_STRING,
  contact_address_2: SCHEMA_STRING,
  contact_city: SCHEMA_STRING,
  contact_state: SCHEMA_STRING,
  contact_zip_code: SCHEMA_STRING,
  status: SCHEMA_STATUS,
  publisher_approval_setting: SCHEMA_STRING,
  global_terms_and_conditions: SCHEMA_STRING,
  publisher_categories: {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        publisher_category_id: SCHEMA_LONG_UUID,
        category: SCHEMA_STRING,
      },
    },
  },
};
