import { Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import imgUrl from '~/lib/helpers.jsx';

export default function BrandToken({ brand }) {
  if (!brand) return null;
  return (
    <Link to={`/brands/${brand.brand_uid}`}>
      <Box
        width='140px'
        height='140px'
        backgroundImage={imgUrl(brand.logo_img_url)}
        backgroundRepeat='no-repeat'
        backgroundSize='cover'
        shadow='xl'
        borderRadius='20px'
      />
    </Link>
  );
}
