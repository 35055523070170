export const IMAGE_CONTAIN = {
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
};
export const IMAGE_COVER = {
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
};

export const OFFER_DESC = {
  fontSize: 'sm',
  textAlign: 'center',
  fontWeight: 600,
  noOfLines: [2, 3],
};
export const HERO_HEAD_TXT = {
  textAlign: 'center',
  m: 0,
};
export const HERO_HEAD_BRAND = {
  textAlign: 'center',
  fontSize: ['md', 'lg', 'lg'],
  as: 'h3',
  lineHeight: 1.2,
  fontWeight: 300,
  pt: 2,
};
export const MAJOR_HEAD = {
  textAlign: 'center',
  color: 'blackAlpha.800',
  fontSize: ['20px', '18px', '20px', '20px'],
  as: 'h1',
  fontWeight: 600,
  pb: 3,
};
export const MINOR_HEAD = {
  textAlign: 'center',
  color: 'blackAlpha.800',
  fontSize: ['18px', '16px', '18px', '18px'],
  as: 'h2',
  fontWeight: 600,
  pb: 3,
};
export const OFFER_HEAD = {
  textAlign: 'center',
  fontSize: '2xl',
  pb: 2,
};
export const OFFER_BRAND = {
  textAlign: 'center',
  fontSize: 'sm',
  pt: 2,
};

export const CAROUSEL = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  borderRadius: '25px',
  backgroundColor: 'lime',
  p: '30px',
};
export const BRAND_SIZE = '120px';
export const BRAND_SIZE_HERO = '200px';
export const BRAND_SIZE_SM = '80px';
export const HEAD_BG_LIGHT = {
  bgGradient: 'linear(to-b, whiteAlpha.800, whiteAlpha.500, transparent)',
};
export const HEAD_BG_DARK = {
  bgGradient: 'linear(to-b, blackAlpha.800,  blackAlpha.500, transparent)',
};
export const HEAD_BG_GRAY = {
  bgGradient: 'linear(to-b, grayAlpha-800, grayAlpha-500, transparent)',
};
export const DESC_BG_DARK = {
  bgGradient: 'linear(to-t, blackAlpha.900,  blackAlpha.700, blackAlpha.50)',
};
export const DESC_BG_LIGHT = {
  bgGradient: 'linear(to-t, whiteAlpha.900, whiteAlpha.200)',
};
export const DESC_BG_GRAY = {
  bgGradient: 'linear(to-t, grayAlpha-900, grayAlpha-700, grayAlpha-50)',
};
export const HEAD_HERO_BG_LIGHT = {
  bgGradient: 'linear(to-b, whiteAlpha.800, whiteAlpha.600, whiteAlpha.50)',
};
export const HEAD_HERO_BG_DARK = {
  bgGradient: 'linear(to-b, blackAlpha.800,  blackAlpha.600, blackAlpha.50)',
};
export const HEAD_HERO_BG_GRAY = {
  bgGradient: 'linear(to-b, grayAlpha-800, grayAlpha-600, grayAlpha.50)',
};
export const DESC_HERO_BG_DARK = {
  bgGradient: 'linear(to-t, blackAlpha.800,  blackAlpha.600, blackAlpha.50)',
};
export const DESC_HERO_BG_GRAY = {
  bgGradient: 'linear(to-t, grayAlpha-800, grayAlpha-600, grayAlpha-50)',
};

export const BGI = {
  position: 'absolute',
  zIndex: 5,
  pointerEvents: 'none',
  left: 0,
  right: 0,
};
const BG = {
  ...BGI,
  width: '100%',
};
export const OFFER_DESC_BG = {
  ...BG,
  bottom: 0,
  px: [2, 2, 4],
  pb: 2,
  pt: 3,
  pointerEvents: 'none',
};

export const BRAND_DESC_BG = {
  width: '100%',
  bottom: 0,
  px: [2, 2, 4],
  pb: 2,
  pt: 3,
  pointerEvents: 'none',
};
export const HERO_HEAD_BG = {
  width: '100%',
  py: 4,
  pointerEvents: 'none',
  textAlign: 'left',
};
export const OFFER_HEAD_BG = {
  zIndex: 200,
  py: 2,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  pointerEvents: 'none',
};
export const BRAND_HEAD_BG = {
  width: '100%',
  py: [2, 2, 2, 3],
  pointerEvents: 'none',
};
export const TEXTBOX_MAX_WIDTH = '900px';

export const BRAND_PAGE_DESC = {
  fontSize: ['0.7rem', '0.7rem', '0.8rem', '0.9rem'],
};
export const FORM_MAX_WIDTH = '800px';
export const GRAY_ALPHAS = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900].reduce((obj, num) => {
  return { ...obj, [`grayAlpha-${num}`]: `rgba(128,128,128,${num / 1000})` };
}, {});

export const BRAND_WIDTH = '270px';
export const BRAND_WIDTH_SM = '250px';

export const PAGE_CONTAINER_INNER_MAX_WIDTH_PX = '1200px';
export const BRAND_BANNER_HEIGHT = '130px';

export const STORE_LOGO_SIZE_PX = '140px';
export const BRAND_IMAGE_RADIUS = '13px';
export const BRAND_PADDING = '13px';
export const BRAND_BOTTOM_PADDING = '19px';

export const BRAND_PAGE_LOGO_HEIGHT = 170;
export const BRAND_PAGE_LOGO_PX = asPx(BRAND_PAGE_LOGO_HEIGHT);
export const BRAND_PAGE_BANNER_PX = '240px';

function asPx(n) {
  return `${Number(n)}px`;
}
